import fetch from "isomorphic-fetch"; 

export function fetchCircuits( ) {
    return fetch( "http://ergast.com/api/f1/2018/circuits.json" )
        .then( res => res.json( ) )
        .then( res => res.MRData.CircuitTable.Circuits );
}

export function saveSchool( ) {
    return fetch( "http://schoolapi.osscedu.org/api/schools" )
        .then( res => res.json( ) )
        .then( res => res.MRData.CircuitTable.Circuits );
}

export function fetchLogin(email,password) {
		let formData = new FormData()
		formData.append('email',email)
		formData.append('password',password)

		return fetch("http://schoolapi.osscedu.org/api/user/login", 
			{
				method: 'POST',
				headers: new Headers({ 'Accept': 'application/json'}),
				body: formData
			} 
		).then(res => res.json( ) ).then( res => res ); 
}