import React from "react"; 
import {Link} from 'react-router-dom';
import { Switch, Route } from "react-router-dom"; 
import { connect } from 'react-redux';
import school_api from "../../school_api" 
import {STUDENT_PAGE_LOADED,ADD_STUDENT,DELETE_SCHOOL} from "../../constants/actionTypes";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";
const mapStateToProps = state => ({
  ...state.student 
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => {
     console.log(payload);
     payload.then(res=> dispatch({ type: STUDENT_PAGE_LOADED, data:res[0] }) )
  },  
     
});

class PrintMarkSheet extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
            s_roll_number:'',
            print_date:'',
            totalMarks:'',
            resutlMarks:'',  
            print_date2:"",
            print_date_held:new Date(),
            isLoading: false,
            student:{},
            page:0,
            loginClass: ''
        };
        
    }

    changeValue=(variable) => (ev) => {
        const obj = this.state;
        var value = ev.target.value;
        obj[variable] = value; 
        this.setState(obj);  
    }

    changeDate=() => (ev) => {
        const obj = this.state;
        obj['print_date'] =ev.target.value;
        var d =  new Date(ev.target.value);
        obj['print_date2'] = d.getDate()+"-"+(d.getMonth()+1)+"-"+d.getFullYear();; 
        this.setState(obj);  
    }

    heldDate=() => (ev) => {
        const obj = this.state;
        //obj['print_date'] =ev.target.value;
        var d =  new Date(ev.target.value);
        obj['print_date_held'] = d;
        this.setState(obj);  
    }
    
    
     
    submitForm=() => (ev) => {
        ev.preventDefault();
        if(this.state.s_roll_number!=""){
            this.generateAdmitCard(this.state.s_roll_number);
        }else{
            alert("Please enter roll number.");
        }
    }

    printPdfMarksheet= () =>{
        document.getElementById("print_date_input").style.display="none";

        
        //console.log(format1); // 07/23/2022
        document.getElementById("print_date_held").style.display="none";
        var printContents = document.getElementById("printDiv").innerHTML;
        var originalContents = document.body.innerHTML;
   
        document.body.innerHTML = printContents;
   
        window.print();
             
        document.body.innerHTML = originalContents;

       
    }

    componentDidMount() {
        //this.loadData(0);
    }

    convertNumberToString = (num)=>{
      if(!num){
        return '';
      }
      var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];


    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? ' ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
    return str;
    }

    generateAdmitCard = (studentId) => {
        this.setState({ isLoading: true }); 
        school_api.Students.getStudentByRollNumber(studentId).then(res => {
            let totalMark=0;
            let resutl="Fail";
            if(res.success) {
                res.data.documents = res.data.documents!="" ? JSON.parse(res.data.documents) : {};
                 var d =  new Date(res.data.dob); 
                    
                    res.data.dob = d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();

                if(res.data.subjects.length>0){
                      resutl='Pass';
                    res.data.subjects.map((subject,index)=>{
                       
                        
                        if(subject.subject.practical=="Yes") {
                            res.data.subjects[index].practical_mark = isNaN(res.data.subjects[index].practical_mark) || res.data.subjects[index].practical_mark==null ? 0 : res.data.subjects[index].practical_mark;
                            
                            res.data.subjects[index].practical_mark=parseInt(res.data.subjects[index].practical_mark);
                            res.data.subjects[index].total_subject = parseInt(subject.marks)+parseInt(subject.practical_mark);

                            res.data.subjects[index].sub_result = res.data.subjects[index].total_subject>=parseInt(subject.subject.passing_mark) ? 'Pass' : 'Fail';
                            console.log(res.data.subjects[index]);
                        }else{
                            res.data.subjects[index].total_subject = parseInt(subject.marks);

                            res.data.subjects[index].sub_result = res.data.subjects[index].total_subject>=parseInt(subject.subject.passing_mark) ? 'Pass' : 'Fail';
                        }

                        if(res.data.subjects[index].total_subject>parseInt(subject.passing_mark)) {
                            resutl="Fail";
                        }
                         totalMark = totalMark+res.data.subjects[index].total_subject ;
                        
                    })
                }
                this.setState({ isLoading: false,student:res.data,totalMarks:totalMark,resutlMarks:resutl });
            }else{
                this.setState({ isLoading: false ,student:{},totalMarks:totalMark,resutlMarks:resutl });
                alert("Student does not exist.");
            }
        });
    }
     
    shouldComponentUpdate(nextProps){ 
        
        return true;
    }
    printMarks = () => {
        
        return <div></div>;
    }
    render() {

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const d = this.state.print_date_held;
        return ( 
           <div>
               <form onSubmit={this.submitForm()}  >
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                Search
                            </h2>
                        </div>
                        <div className="body">
                            
                            <div className="row clearfix">
                                <div className="col-md-3">
                                    <label htmlFor="email_address">Roll Number</label>
                                    <div className="form-group">
                                        <div className="form-line ">
                                            <input type="text" id="s_roll_number" className="form-control" onChange={this.changeValue('s_roll_number')} value={this.state.s_center} placeholder="Enter Roll Number" />
                                        </div>
                                    </div>
                                </div> 

                            </div>
                            <button class="btn btn-primary waves-effect" type="submit">Search</button>
                        </div>
                    </div>
            </div>
            </form>
            {this.state.student.id && 
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

<div className="card">                            
<div className="body">

<div className="table-responsive">
<div id="printDiv">

 
{/*<img src="/img/mrk.jpg" />*/}

<style>
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@500&display=swap');
</style>


<table width="900" border="0" style={{fontFamily:"'Libre Franklin', sans-serif", fontSize:"14px", textTransform:"uppercase"}}>
  <tr>
    {/* <td colspan="3" style={{paddingLeft: "250px", height:"100px", position:"relative" }}> <p style={{position:"absolute", marginBottom:"0", marginTop:"-11px", marginLeft:"-10px" }}> {Date.now()} </p></td> */}
    <td colspan="3" style={{paddingLeft: "250px", height:"100px", position:"relative" }}> <p style={{position:"absolute", marginBottom:"0", marginTop:"-30px", marginLeft:"-10px" }}> <span contenteditable="true" style={{borderBottom:"1px #cccccc78 dashed", width:"200px", display:"block"}}>
  
  </span> </p></td>
  </tr>
  <tr>
    <td height="150" colspan="3">&nbsp;</td>
  </tr>
  <tr>
    <td style={{paddingLeft: "225px", verticalAlign:"bottom", height:"100px", position:"relative"}}> <p style={{position:"absolute", marginBottom:"0", marginTop:"-9px" }}>{this.state.student.roll_no}</p></td>
    <td>&nbsp;</td>
    <td style={{paddingLeft: "40px", verticalAlign:"bottom", height:"100px", position:"relative"}}> <p style={{position:"absolute", marginBottom:"0", marginTop:"-8px", marginLeft:"-15px" }}>{this.state.student.enrollment_no}</p></td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td style={{paddingLeft: "40px", verticalAlign:"bottom", height:"100px", position:"relative"}}><p style={{position:"absolute", marginBottom:"0", marginTop:"-63px" }}>{this.state.student.name}</p></td>
    <td rowspan="4" style={{textAlign: "right", paddingRight: "70px", paddingTop: "0px"}}><img  src={"http://schoolapi.osscedu.org/student/download/"+this.state.student.id+"/passport_photo"} style={{width:"106px", marginTop:"-175px"}} /></td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td style={{position: "relative", verticalAlign:"bottom", height:"100px"}}><p style={{position:"absolute", marginBottom:"0", marginTop:"-118px" }}>{this.state.student.father_name}</p></td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td style={{position: "relative", verticalAlign:"middle", height:"100px"}}><p style={{position:"absolute", marginBottom:"0", marginTop:"-128px" }}>{this.state.student.mother_name}</p></td>
  </tr>
  <tr>
    <td style={{paddingLeft: "250px", position: "relative", height:"90px"}}><p style={{position:"absolute", marginBottom:"0", marginTop:"-182px" }}>{this.state.student.dob}</p></td>
    <td style={{paddingLeft: "160px", position: "relative", height:"100px"}}><p style={{position:"absolute", marginBottom:"0", marginTop:"-182px", marginLeft:"10px" }}>{this.state.student.medium}</p></td>
  </tr>
  <tr>
    <td style={{paddingLeft: "100px", position: "relative", height:"50px"}}><p style={{position:"absolute", marginBottom:"0", marginTop:"-207px" }}><input type="date" value="" id="print_date_held" onChange={this.heldDate()}  /> {monthNames[d.getMonth()]} / {d.getFullYear()}</p></td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td style={{paddingLeft: "12px", position: "relative", textAlign:"left", height:"100px"}}><p style={{position:"absolute", marginBottom:"0", marginTop:"-250px", marginLeft:"-120px" }}> {this.state.student.school.name}</p> </td>
    <td>&nbsp;</td>
  </tr>
  
  
   {this.state.student.subjects.length>0 &&
  <tr>
    <td colspan="3" style={{fontSize: "12px"}}>
   
    <table width="800" border="0" cellspacing="0" cellpadding="0" style={{heiht:"250px", fontSize: "12px", marginTop:"-150px", marginLeft:"30px"}}>
    <tbody>
   {this.state.student.subjects.length>0 && this.state.student.subjects.map((subject) => 
  <tr>
    
    <td style={{paddingLeft: "40px", width: "245px", textAlign:"center", paddingBottom:"10px" }}>{subject.subject.name} </td>
	  <td style={{paddingLeft: "35px", width: "140px", textAlign:"center", paddingBottom:"10px" }}>{subject.subject.s_code} </td>
    <td style={{width: "125px", textAlign:"center", paddingBottom:"10px"}}>100</td>
    <td style={{width: "95px", paddingLeft: "0px", textAlign:"center", paddingBottom:"10px"}}>{subject.marks} </td>
    <td style={{width: "120px", textAlign:"center", paddingBottom:"10px"}}>{ subject.subject.practical=="Yes" &&subject.practical_mark} </td>
    <td style={{width: "120px", paddingLeft: "15px", textAlign:"left", paddingBottom:"10px"}}>{subject.total_subject}</td>
    <td style={{textAlign:"left", width: "200px", paddingBottom:"10px"}}><span style={{position: "absolute", marginLeft: "15px", marginTop: "-8px", textTransform: "uppercase", display:"block", width: "150px"}}>{this.convertNumberToString(subject.total_subject)}</span></td>
  </tr>
   ) }
    {this.state.student.subjects.length<6 &&
   <tr>
    <td style={{paddingLeft: "35px", width: "220px", textAlign:"center", paddingBottom:"10px"}}>&nbsp;</td>
    <td style={{paddingLeft: "40px", width: "105px", paddingBottom:"10px"}}>&nbsp;</td>
    <td style={{width: "70px", textAlign:"center", paddingBottom:"10px"}}>100</td>
    <td style={{width: "80px", textAlign:"center", paddingBottom:"10px"}}>&nbsp;</td>
    <td style={{width: "75px", textAlign:"center", paddingBottom:"10px"}}>&nbsp;</td>
    <td style={{width: "100px", textAlign:"center", paddingBottom:"10px"}}>&nbsp;</td>
	<td style={{textAlign:"left", width: "200px", paddingBottom:"10px"}}><span style={{position: "absolute", marginLeft: "-25px", marginTop: "-5px"}}>&nbsp;</span></td>
  </tr>
  }
  
   
   <tr>
    <td colspan="7">&nbsp;</td>
  </tr>
  <tr>
    <td colspan="7">&nbsp;</td>
  </tr>
  <tr>
    <td colspan="7">&nbsp;</td>
  </tr>
  <tr>
    <td colspan="7">&nbsp;</td>
  </tr>
  <tr>
    <td colspan="7">&nbsp;</td>
  </tr>
  </tbody>
</table>

<table width="700" border="0" cellspacing="0" cellpadding="0" style={{marginTop:"-20px", marginLeft:"40px"}}>
  <tr>
    <td width="220">&nbsp;</td>
    <td style={{fontSize:"24px", fontFamily:"'Alegreya', serif"}}><strong>{this.state.totalMarks}</strong></td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td style={{textTransform: "uppercase", fontSize:"20px", fontFamily:"'Alegreya', serif", position: "relative" }}><p style={{position:"absolute", marginBottom:"0", marginTop:"10px" }}>{this.convertNumberToString(this.state.totalMarks)}</p></td>
  </tr>
   
  <tr>
    <td>&nbsp;</td>
    <td style={{position: "relative", height:"100px" }}> <p style={{position:"absolute", marginBottom:"0", marginTop:"0px", fontSize:"14px" }}><input type="date" value={this.state.print_date} id="print_date_input" onChange={this.changeDate()}  /> {this.state.print_date2}</p></td>
  </tr>
</table>

    
    </td>
  </tr>
  }
  
  
  
</table>



</div>
<div className="clearfix"></div>
<button class="btn btn-primary waves-effect" type="button" onClick={ ()=> this.printPdfMarksheet()}>Print</button>
 </div>
        </div>
    </div>
    <Loader isloading={this.state.isLoading}/>
</div>
}

</div>
         
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintMarkSheet);
