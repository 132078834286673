import React from "react"; 
import {Link} from 'react-router-dom';
import { Switch, Route } from "react-router-dom"; 
import { connect } from 'react-redux';
import school_api from "../../school_api" 
import {STUDENT_PAGE_LOADED,ADD_STUDENT,DELETE_SCHOOL} from "../../constants/actionTypes";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";
const mapStateToProps = state => ({
  ...state.student 
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => {
     console.log(payload);
     payload.then(res=> dispatch({ type: STUDENT_PAGE_LOADED, data:res[0] }) )
  },  
     
});

class PrintMigration extends React.Component {
    constructor() {
        super();
       this.state = {
            title: "Welcome to React SSR!",
            s_roll_number:'',
            print_date:'',
            totalMarks:'',
            resutlMarks:'',  
            print_date2:"",
            print_date_held:new Date(),
            isLoading: false,
            student:{},
            page:0,
            loginClass: ''
        };
        
        
    }

    changeValue=(variable) => (ev) => {
        const obj = this.state;
        var value = ev.target.value;
        obj[variable] = value; 
        this.setState(obj);  
    }

    changeDate=() => (ev) => {
        const obj = this.state;
        obj['print_date'] =ev.target.value;
        var d =  new Date(ev.target.value);
        obj['print_date2'] = d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();; 
        this.setState(obj);  
    }

    heldDate=() => (ev) => {
        const obj = this.state;
        //obj['print_date'] =ev.target.value;
        var d =  new Date(ev.target.value);
        obj['print_date_held'] = d;
        this.setState(obj);  
    }
    
    
     
    submitForm=() => (ev) => {
        ev.preventDefault();
        if(this.state.s_roll_number!=""){
            this.generateAdmitCard(this.state.s_roll_number);
        }else{
            alert("Please enter roll number.");
        }
    }

    printPdfMarksheet= () =>{
        document.getElementById("print_date_input").style.display="none";
        document.getElementById("print_date_held").style.display="none";
        var printContents = document.getElementById("printDiv").innerHTML;
        var originalContents = document.body.innerHTML;
   
        document.body.innerHTML = printContents;
   
        window.print();
             
        document.body.innerHTML = originalContents;
    }

    componentDidMount() {
        //this.loadData(0);
    }
    generateAdmitCard = (studentId) => {
        this.setState({ isLoading: true }); 
        school_api.Students.getStudentByRollNumber(studentId).then(res => {
            let totalMark=0;
            let resutl="Fail";
            if(res.success) {
                var d =  new Date(res.data.dob);                     
                res.data.dob = d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();

                if(res.data.subjects.length>0){
                      resutl='Pass';

                    res.data.subjects.map((subject)=>{
                        totalMark = totalMark+parseInt(subject.marks) ;
                        if(subject.subject.passing_mark>parseInt(subject.marks)) {
                            resutl="Fail";
                        }
                    })
                }

                var dobObj = new Date(res.data.dob);


                this.setState({ isLoading: false,student:res.data,totalMarks:totalMark,resutlMarks:resutl });
            }else{
                this.setState({ isLoading: false ,student:{},totalMarks:totalMark,resutlMarks:resutl });
                alert("Student does not exist.");
            }
        });
    }
     
    shouldComponentUpdate(nextProps){ 
        
        return true;
    }
    printMarks = () => {
        
        return <div>Hiiiii</div>;
    }
    render() {
         
        
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const d = this.state.print_date_held;
        return ( 
           <div>
               <form onSubmit={this.submitForm()}  >
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                Search
                            </h2>
                        </div>
                        <div className="body">
                            
                            <div className="row clearfix">
                                <div className="col-md-3">
                                    <label htmlFor="email_address">Roll Number</label>
                                    <div className="form-group">
                                        <div className="form-line ">
                                            <input type="text" id="s_roll_number" className="form-control" onChange={this.changeValue('s_roll_number')} value={this.state.s_center} placeholder="Enter Roll Number" />
                                        </div>
                                    </div>
                                </div> 

                            </div>
                            <button class="btn btn-primary waves-effect" type="submit">Search</button>
                        </div>
                    </div>
            </div>
            </form>
            {this.state.student.id && 
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

<div className="card">                            
<div className="body" style={{height: "1000px"}}>

<div className="table-responsive">
<div id="printDiv">

 
{/*<img src="/img/secondary-mark-sheet.jpg" />*/}


<style>
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@500&display=swap');
</style>

<table  width="800" border="0" cellspacing="0" cellpadding="0" style={{fontFamily:"'Libre Franklin', sans-serif", height:"100%", position:"absolute", top:0}}>


  
  {/* <tr>
    <td style={{paddingTop: "15px", verticalAlign:"top", paddingLeft:"180px", height:"50px"}}> <strong>{Date.now()} </strong></td>
    <td>  </td>
  </tr> */}

<tr>
    {/* <td colspan="3" style={{paddingLeft: "250px", height:"100px", position:"relative" }}> <p style={{position:"absolute", marginBottom:"0", marginTop:"-11px", marginLeft:"-10px" }}> {Date.now()} </p></td> */}
    <td style={{paddingTop: "0px", verticalAlign:"top", paddingLeft:"180px", height:"50px"}}> <span contenteditable="true" style={{borderBottom:"1px #cccccc78 dashed", width:"200px", display:"block"}}>
  
  </span></td>

  
  </tr>
  
  <tr>
    <td style={{verticalAlign:"top", height:"50px"}}><p style={{paddingTop:"140px", verticalAlign:"top", paddingLeft:"180px"}}> <strong>{this.state.s_roll_number}</strong></p></td>
    <td style={{verticalAlign:"top", height:"50px"}}><p style={{paddingTop:"140px", verticalAlign:"top", paddingLeft:"250px"}}> <strong>{this.state.student.enrollment_no}</strong></p></td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td style={{verticalAlign:"top", height:"50px"}}> <p style={{marginTop: "-110px", marginLeft: "20px", position: "relative"}}> <strong>{this.state.student.name}</strong></p> </td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td style={{verticalAlign:"top", height:"50px"}}><p style={{marginTop:"-160px", position: "relative"}}> <strong>{this.state.student.father_name}</strong></p></td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td style={{verticalAlign:"top", height:"50px"}}><p style={{marginTop:"-210px", position: "relative"}}> <strong>{this.state.student.mother_name}</strong></p></td>
  </tr>
  <tr>
    <td style={{verticalAlign:"top", height:"50px"}}><p style={{marginTop:"-250px", marginLeft: "250px", position: "relative"}}><strong>{this.state.student.dob}</strong></p></td>
    <td style={{verticalAlign:"top", height:"50px"}}><p style={{marginTop:"-250px", marginLeft: "150px", position: "relative"}}><strong>{this.state.student.medium}</strong></p></td>
  </tr>
  <tr>
    <td colspan="2" style={{verticalAlign:"top", height:"50px"}}><p style={{marginTop:"-297px", marginLeft: "200px", position: "absolute"}}> <strong>{this.state.student.coursename && this.state.student.coursename.name} </strong></p></td>
  </tr>
  
  <tr>
    <td style={{verticalAlign:"top", height:"50px"}}> <p style={{marginTop:"-346px", marginLeft: "65px", position: "absolute"}}> <strong>{monthNames[d.getMonth()]} – {d.getFullYear()}</strong> <input type="date" value="" id="print_date_held" onChange={this.heldDate()}  /></p></td>
    <td style={{verticalAlign:"top", height:"50px"}}> <p style={{marginTop:"-346px", marginLeft: "200px", position: "absolute"}}> <select style={{fontWeight:"bold", background:"transparent", border:"0", appearance:"none" }}>
  <option value="volvo">First</option>
  <option value="saab">Second</option>
  <option value="mercedes">Third</option>
  <option value="audi">Fail</option>
</select></p></td>
 
  </tr>
  
 
  
  
  <tr>
    <td style={{verticalAlign:"top", height:"50px"}}> <p style={{marginTop:"-250px", marginLeft: "150px", position: "absolute"}}><strong><input type="date" value={this.state.print_date} id="print_date_input" onChange={this.changeDate()}  /> {this.state.print_date2}</strong></p> </td>
    <td style={{verticalAlign:"top", height:"50px"}}></td>
  </tr>
</table>









</div>
<div className="clearfix"></div>
<button class="btn btn-primary waves-effect" type="button" onClick={ ()=> this.printPdfMarksheet()}>Print</button>
 </div>
        </div>
    </div>
    <Loader isloading={this.state.isLoading}/>
</div>
}

</div>
         
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintMigration);
