import React from "react";
import {Link,Redirect} from 'react-router-dom'; 
import { connect } from 'react-redux';
import {ADD_STUDENT, GET_STUDENT_INFO, UPDATE_STUDENT} from "../../constants/actionTypes"
import school_api from "../../school_api" 
import Loader from "../Loader/Loader"; 

const mapDispatchToProps = dispatch => ({
  onSubmit: payload =>
    dispatch({ type: ADD_STUDENT, ...payload }),
  onEdit: payload =>
    dispatch({ type: GET_STUDENT_INFO, ...payload }),
  onUpdate: payload =>
    dispatch({ type: UPDATE_STUDENT, ...payload })
});

const mapStateToProps = state => ({
  ...state.student
});

class StudentEdit extends React.Component {
    constructor() {
        super();
        this.state = {
            school_id: '',
            session: "",
            dob:"",
            roll_no:"",
            status:'Active',
            medium:'',
            enrollment_no:"",
            gender:"Male",
            name:"",
            father_name:"",
            mother_name:"",
            mobile:"",
            email:"",
            course:"",
            address:"",
            mother_tongue:"",
            nationality:"",
            previous_qualification:"",
            amount:"",
            screenshot:"",
            order_no:"",
            student_type:"",
            center_name:"",
            center_code:"",
            mode:"edit",
            isLoading:false,
            redirect:false,
            totalsubject:{number_of_subject:0,optional_subject:0}
        };

        this.setStatus = ev => {
            this.setState({status: ev.target.value });
        };

        this.onSubmit= () => (ev) => {
            ev.preventDefault(); 
            this.setState({isLoading:true}); 
            let subjectArray = []; 
            let totalSubject = (this.state.totalsubject.number_of_subject + this.state.totalsubject.optional_subject);
            
            for(var i=0; i< totalSubject ; i++) {
                let v = document.getElementById('subject_'+i).value; 
                let optional = "No";
                if(i>(this.state.totalsubject.number_of_subject-1)) {
                  optional = "Yes";
                } 
                subjectArray.push({id:v,optional:optional}); 
            }
            let postObject = {...this.state, subjects:subjectArray};
           
            
            this.saveStudentForm(postObject)         
        }
    }

    saveStudentForm = (data) => {
        let payload = '';
        
         payload = school_api.Students.updateStudent(this.props.student_id, data);
        

        payload.then(
        res=>{ 
                this.dispatchAction(res);
                this.setState({redirect:true});
          },
        error=>
            {                
                this.dispatchAction(error.response.body)
            } 
        );
    } 

    dispatchAction = (res,action) => {
        this.setState({isLoading:false});
        if(this.props.edit_id!="") { 
            this.props.onUpdate(res);
        }else{
             this.props.onSubmit(res);
        }
    }
    
    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    changeValue=  (variable) => (ev) => {
        const obj = this.state;

        if(variable != 'screenshot') {
            var value = ev.target.value;
            obj[variable] = value;

            if(variable == 'course' && value!="") {
                const object =  this.props.course.filter(r=> r.id == value );
                 
                obj['amount']=object[0].cost;
                obj['totalsubject']['number_of_subject'] = parseInt(object[0].number_of_subject);
                obj['totalsubject']['optional_subject'] = parseInt(object[0].optional_subject);
            }
        }
        
         

        if(variable == 'screenshot') {
            let d = this.toBase64(document.getElementById('id_screenshot').files[0]);
            d.then(data=> {
                obj['screenshot']=data
                this.setState(obj)
            }); 
            
        }else{
            this.setState(obj);  
        }


    }
    getEditRecrod =() =>{
        if(this.props.student_id) { 
            this.setState({ isLoading: true });
           school_api.Students.getStudent(this.props.student_id).then(res=>this.props.onEdit(res)) ;
       }
    }

    componentDidMount() {
        this.setState({school_id:this.props.school_id});
        this.getEditRecrod();
    }

    openQrCode = () => {
        //alert("hi");
       //window.open("/img/qr_code.jpeg", "QR Code", "width=400,height=400");
       let newWin = window.open("", "QR Code", "width=400,height=800");
       
       newWin.document.write(
        ' <title>QR Code</title><img width="400"  src="/img/qr_code.jpeg" />'
       );
    }
     

    shouldComponentUpdate(nextProps){
        if(nextProps.action && this.state.isLoading == true) {
          if(this.props.success && this.state.mode=='edit' && (this.props.action != GET_STUDENT_INFO)) {
            this.setState({ isLoading: false ,mode:'done'});
          }else{
            this.setState({ isLoading: false });
          }
        }

        if(this.props.edit_id!="" && nextProps.action == GET_STUDENT_INFO && this.state.name == '' ) {
            this.setState({...nextProps.student,isLoading:false});
        }
        return true;
    }

    downloadLink = (fileType) => {
        let document = this.state.screenshot;
         
        if(typeof document!= 'undefined' && document!='') {
            return `<a href="${school_api.HOST_ROOT}/student/download/${this.props.student.id}/${fileType}" target="_blank" >Download</a>`;
        }
       return ``;
    }

    render() {

        if(this.state.redirect) {
          return <Redirect to='/students/list' />
        }

        const male = (this.state.gender == 'Male')
        const female = (this.state.gender == 'Female')

        const Regular = (this.state.student_type == 'Regular')
        const Open = (this.state.student_type == 'Open') 
        const Private = (this.state.student_type == 'Private')

        const active = this.state.status == 'Active' ?true:false;
        const inactive = this.state.status == 'Inactive' ?true:false; 

        const showHide = this.state.course!="" ? false : true;

        return (
                <form onSubmit={this.onSubmit()} id="studentAdd">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                    <div className="card">
                        <div className="header">
                            <h2>
                                Edit Students | <Link to="/students/list" className="btn btn-danger waves-effect">Back</Link>
                            </h2>
                            
                        </div>
                        <div className="body">
                            <div>

                             { this.props.studentErrors && 
                                <ul>
                                {this.props.studentErrors && this.props.studentErrors.map( error=>(
                                    <li key={error}>{error} </li>
                                ))}
                                </ul>  
                             }

                            </div>
                           
                                <input type="hidden" id="s_school_id" value={this.state.school_id}   />

                                <label htmlFor="password">Student Type</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="radio" value="Regular" checked={Regular} onChange={this.changeValue('student_type')} name="student_type"  />Regular
                                        &nbsp;&nbsp;
                                        <input type="radio" value="Private" checked={Private} onChange={this.changeValue('student_type')} name="student_type"   />Private

                                        &nbsp;&nbsp;
                                        <input type="radio" value="Open" checked={Open} onChange={this.changeValue('student_type')} name="student_type"   />Open
                                    </div>
                                </div>



                                <label htmlFor="password">Center Name</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_center_name" value={this.state.center_name} onChange={this.changeValue('center_name')}  name="center_name" className="form-control" placeholder="Enter your center_name" />
                                    </div>
                                </div>


                                <label htmlFor="password">Center Code</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_center_code" value={this.state.center_code} onChange={this.changeValue('center_code')}  name="center_code" className="form-control" placeholder="Enter your center code" />
                                    </div>
                                </div>

                                <label htmlFor="email_address">Session</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_session" value={this.state.session} onChange={this.changeValue('session')} name="session" className="form-control" placeholder="Enter your session"  />
                                    </div>
                                </div>

                                <label htmlFor="email_address">Roll No</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_roll_no" value={this.state.roll_no} onChange={this.changeValue('roll_no')} name="roll_no" className="form-control" placeholder="Enter your roll no"  />
                                    </div>
                                </div>

                                <label htmlFor="email_address">Enrollment No</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_enrollment_no" value={this.state.enrollment_no} onChange={this.changeValue('enrollment_no')} name="enrollment_no" className="form-control" placeholder="Enter your Enrollment No"  />
                                    </div>
                                </div>

                                <label htmlFor="password">Name</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_name" value={this.state.name} onChange={this.changeValue('name')}  name="name" className="form-control" placeholder="Enter your name" />
                                    </div>
                                </div>

                                <label htmlFor="password">Father Name</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_f_name" value={this.state.father_name} onChange={this.changeValue('father_name')} name="father_name" className="form-control" placeholder="Enter your Father name" />
                                    </div>
                                </div>

                                <label htmlFor="password">Mother Name</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_m_name" value={this.state.mother_name} onChange={this.changeValue('mother_name')} name="mother_name" className="form-control" placeholder="Enter your Mother name" />
                                    </div>
                                </div>
                                <label htmlFor="password">Email</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_email"  value={this.state.email} onChange={this.changeValue('email')} name="email" className="form-control" placeholder="Enter your Email" />
                                    </div>
                                </div>
                                <label htmlFor="password">DOB</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="date" id="s_dob"  value={this.state.dob} onChange={this.changeValue('dob')} name="dob" className="form-control" placeholder="Enter your DOB" />
                                    </div>
                                </div>

                                <label htmlFor="password">Gender</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="radio" value="Male" checked={male} onChange={this.changeValue('gender')} name="gender"  />Male
                                        &nbsp;&nbsp;
                                        <input type="radio" value="Female" checked={female} onChange={this.changeValue('gender')} name="gender"   />Famale
                                    </div>
                                </div>
                                <label htmlFor="password">Medium</label>
                            <div className="form-group">
                                <div className="form-line">
                                    <input type="text" id="s_medium" value={this.state.medium} onChange={this.changeValue('medium')} name="medium" className="form-control" placeholder="Enter your medium" />
                                </div>
                            </div>
                                <label htmlFor="password">Mobile Number</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_mobile" value={this.state.mobile} onChange={this.changeValue('mobile')} name="mobile" className="form-control" placeholder="Enter your mobile" />
                                    </div>
                                </div>
                                <label htmlFor="password">Address</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <textarea name="address" value={this.state.address} onChange={this.changeValue('address')} name="address" className="form-control" placeholder="Enter your address" ></textarea>
                                    </div>
                                </div>

                                <label htmlFor="password">Mother Tounge</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_mother_tongue" value={this.state.mother_tongue} onChange={this.changeValue('mother_tongue')}  name="mother_tongue" className="form-control" placeholder="Enter mother tongue" />
                                    </div>
                                </div>

                                <label htmlFor="password">Nationality</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_nationality" value={this.state.nationality} onChange={this.changeValue('nationality')}  name="nationality" className="form-control" placeholder="Enter nationality" />
                                    </div>
                                </div>

                                <label htmlFor="password">Previous Qualification</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_previous_qualification" value={this.state.previous_qualification} onChange={this.changeValue('previous_qualification')}  name="previous_qualification" className="form-control" placeholder="Enter previous qualification" />
                                    </div>
                                </div>


                                {(this.props.dashboard.user && this.props.dashboard.user.id==1) &&    
                             <>
                                <label htmlFor="password">Status</label>
                                <div className="form-group">
                                    <div  >
                                        <input type="radio" name="radio" name="status" checked={active}  value="Active"
                                        onChange={this.setStatus} />Active
                                         &nbsp;
                                         <input type="radio" name="radio" name="status" checked={inactive} value="Inactive"
                                        onChange={this.setStatus} />Inactive
                                    </div>
                                </div>
                                </>
    }
                                <button type="submit" className="btn btn-primary m-t-15 waves-effect">Save</button>
                        </div>
                       
                    </div>
                    
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="card">
                     <div className="header">
                            <h2>
                              If You change course then you have to update Marks again.
                            </h2> 
                        </div> 
                        <div className="body">
                        <label htmlFor="password">Current Course</label>
                                <div className="form-group">
                                    <div className="form-line">
                                         {this.state.coursename && this.state.coursename.name}

                                    </div>
                                </div>
                        <label htmlFor="password">Current Subjects</label>
                                <div className="form-group">
                                    <div className="form-line">
                                         {this.state.student_subject}
                                         
                                    </div>
                                </div>
                            <label htmlFor="password">Course</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <select className="form-control" name="course" value={this.state.course} onChange={this.changeValue('course')}>
                                            <option value="">Select Course</option>
                                            {this.props.course && this.props.course.map(c=>(
                                                    <option key={c.id} value={c.id} >{c.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                
                                {this.state.totalsubject.number_of_subject>0 &&  <label htmlFor="password">Subjects</label>}
                                {[...Array((this.state.totalsubject.number_of_subject+this.state.totalsubject.optional_subject))].map((x, i) =>
                                    <div key={"subject_option"+i} className="form-group">
                                        <div className="form-line">
                                           {(this.state.totalsubject.number_of_subject-1)>=i && <span>Subject {(i+1)} </span> }
                                           {this.state.totalsubject.number_of_subject<=i && <span >Optional Subject {(i+1)} </span> }
                                            <select className="form-control" id={'subject_'+i} name='subjects' >
                                                <option value="">Select Subject </option>
                                                {this.props.subjects && this.props.subjects.map(c=>{
                                                       
                                                      if(parseInt(c.course_id) == parseInt(this.state.course))  
                                                        return <option key={c.id} value={c.id} >{c.s_code} - {c.name}</option>  
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                )}

                                <div style={{display:"none"}}  >
                                <label htmlFor="password">Amount (INR)</label>
                                <div className="form-group"  >
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <input type="text" id="s_amount" value={this.state.amount} onChange={this.changeValue('amount')} readOnly={true}  name="amount" className="form-control" placeholder="Enter your amount"   />
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{marginBottom: "0px"}}>
                                        <a type="button" className="btn btn-primary waves-effect" onClick={()=>this.openQrCode()} style={{zIndex: "99999999"}}  >Pay</a>
                                    </div>
                                    <div className="form-line">&nbsp;</div>
                                </div>

                                <label htmlFor="password">Order No</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_order_no" value={this.state.order_no} onChange={this.changeValue('order_no')}  name="order_no" className="form-control" placeholder="Enter your order_no" />
                                    </div>
                                </div>

                                <label htmlFor="password">Screenshot</label>
                                <div className="form-group">
                                    <div className="form-line">
                                    <input type="file" onChange={this.changeValue('screenshot')} className="form-control" id="id_screenshot" name="screenshot" />
                                     {this.state.screenshot &&  <div className="content" dangerouslySetInnerHTML={{__html: this.downloadLink('screenshot')}}></div> }
                                    </div>
                                </div>

                                <div style={{display:"none"}}>
                                <label htmlFor="password">QR Code</label>
                                <div className="form-group">
                                    <div className="form-line" style={{textAlign:"center"}}>
                                         <img style={{width:"40%"}} src="/img/qr_code.jpeg" />
                                    </div>
                                </div>
                                </div>
                                 
                                 
                                </div>
                                
                                <button type="submit" className="btn btn-primary m-t-15 waves-effect">Save</button>
                        </div>
                    </div>
                </div>
               
                 <Loader isloading={this.state.isLoading}/>
               </form>
            
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentEdit);
