import React from "react";
import SchoolsList from "./SchoolsList";
import SchoolAdd from  "./SchoolAdd";
import StudentAdd from "../Students/StudentAdd";
import UploadDocument from "../Students/UploadDocument";
import UpdateMarks from "../Students/UpdateMarks";

import { connect } from 'react-redux';
import school_api from "../../school_api" 
import {SCHOOL_PAGE_LOADED,ADD_SCHOOL,DELETE_SCHOOL, SUBJECT_LOAD_AND_COURSE} from "../../constants/actionTypes";
import Loader from "../Loader/Loader";
import StudentsList from "../Students/StudentsList";
//import createStore from "../../stroe";
// const store = createStore( );

   
const mapStateToProps = state => ({
  ...state.school 
});
const mapDispatchToProps = dispatch => ({
  onSubjectLoad: payload => 
     payload.then(res=> dispatch({ type: SUBJECT_LOAD_AND_COURSE, data:res[0] }) ),
     
});

class Schools extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
             
        };

         
    }

   

    componentDidMount() { 
         this.props.onSubjectLoad(Promise.all([school_api.Schools.getSubject()]));
    }

    render() { 
         const addSchool = (this.props.dashboard.user && this.props.dashboard.user.id==1) ? true : false;
        return (
        <section className="content">
            <div className="container-fluid">
            <div className="block-header">
                <h2>Study Center</h2>
            </div> 
            <div className="row clearfix">
            {this.props.match.params.action == 'list' && <SchoolsList dashboard={this.props.dashboard} addSchool={addSchool}/>  }
            {this.props.match.params.action == 'add' && <SchoolAdd  dashboard={this.props.dashboard}  school='' /> }

            {this.props.match.params.action == 'upload-document' && <UploadDocument   dashboard={this.props.dashboard} school_id={this.props.match.params.id} student_id={this.props.match.params.student_id}  /> }
            {this.props.match.params.action == 'upload-marks' && <UpdateMarks  dashboard={this.props.dashboard}  school_id={this.props.match.params.id} student_id={this.props.match.params.student_id}  /> }

            {this.props.match.params.action == 'student' && <StudentAdd course={this.props.course} subjects={this.props.subjects} school_id={this.props.match.params.id} dashboard={this.props.dashboard} edit_id=""  /> }

            {this.props.match.params.action == 'student-list' && <StudentsList  school_id={this.props.match.params.id}  /> }
            
            {this.props.match.params.action == 'edit' && <SchoolAdd school=''  dashboard={this.props.dashboard} sid={this.props.match.params.id} /> }
            </div>
            </div>
            
        </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Schools);;
